(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbAccount.controller:OrdersCtrl
   *
   * @description
   *
   */
  angular
    .module('wbAccount')
    .controller('OrdersCtrl', OrdersCtrl);

  function OrdersCtrl() {
  }
}());
